<template lang="pug">
  main#main.oauth-callback.d-flex.flex-grow-1.align-items-center.justify-content-center
    wc-loaders-cube
</template>

<script>
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'

export default {
  name: 'oauth-callback',
  components: {
    WcLoadersCube,
  },
  mounted() {
    this.$auth
      .signInCallback()
      .then((account) => {
        let params
        try {
          const url = new URL(this.$route.query.redirect_to)
          params = { path: url.pathname }
        } catch {
          params = { name: 'dashboard' }
        }
        this.$router.push(params)
        return account
      })
      .catch((error) => {
        throw error
      })
  },
}
</script>
